import * as React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';

// tslint:disable-next-line: no-submodule-imports
import { FaGitlab, FaCircle } from 'react-icons/fa';

export const Header: React.SFC<{}> = () => (
  <Navbar
    bg="light"
    expand="md"
    style={{ marginBottom: '1em', marginTop: '1em' }}
  >
    <Container>
      <Navbar.Brand
        style={{
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontSize: '1.5rem',
        }}
      >
        <FaCircle style={{ color: 'goldenrod' }} />
        &nbsp;AuDEc
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav fill={true} navbar={true} className="ml-auto justify-content-end">
          <Nav.Item>
            <Nav.Link
              href="https://gitlab.com/slewsystems/pi67"
              target="_blank"
              title="Visit the project on Gitlab"
            >
              <FaGitlab />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
