import * as React from 'react';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';

import { Header } from '../Header';
import { Footer } from '../Footer';

const TemplateWrapper: React.SFC<{
  title: string;
}> = ({ title, children }) => (
  <React.Fragment>
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content:
            'AuDEc is a low latency audio streaming platform for Raspberry Pi using AES67.',
        },
        {
          name: 'keywords',
          content: 'slew systems, audec, respberry pi, aes67, aoip',
        },
      ]}
    />
    <Header />
    <Container fluid={true} style={{ marginTop: '2em' }}>
      {children}
      <Footer />
    </Container>
  </React.Fragment>
);

export default TemplateWrapper;
