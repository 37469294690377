import * as React from 'react';
import { Nav, Navbar, Container, Row, Col } from 'react-bootstrap';

// tslint:disable-next-line: no-submodule-imports
import { FaGitlab } from 'react-icons/fa';

export const Footer: React.SFC<{}> = () => (
  <Container as="footer">
    <Row>
      <Col xs="12">
        <Nav fill={true}>
          <Nav.Item>
            <Nav.Link
              href="https://gitlab.com/slewsystems/pi67"
              target="_blank"
              title="Visit the project on Gitlab"
            >
              <FaGitlab />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
    </Row>
    <Row>
      <Col xs="12" as="p" className="text-center" style={{ fontSize: '.8em' }}>
        Copyright {new Date().getFullYear()}{' '}
        <a href="https://slewsystems.com" target="_blank">
          Slew Systems LLC.
        </a>
      </Col>
    </Row>
  </Container>
);
